<template>
  <div class="flex items-center justify-center h-screen bg-gray-300">
		<!-- Container -->
		<div class="container mx-auto">
			<div class="flex justify-center px-6 my-12">
				<!-- Row -->
				<div class="w-full xl:w-3/5 lg:w-11/12 flex shadow-xl border-1 border-gray-400">
					<!-- Col -->
					<div
            id="animated"
						class="w-full h-auto bg-gray-400 hidden lg:block lg:w-1/2 bg-cover rounded-l-lg"
					>
          </div>
					<!-- Col -->
					<div class="w-full lg:w-1/2 bg-white p-5  lg:rounded-l-none rounded-r-lg">
						<div class="px-8  text-center">
              <h4 class="font-semibold mt-5 mb-5 pb-1 text-3xl">Password Reset Email</h4>
						</div>
            <form>
              <!-- BEGIN: Email --->
              <div>
                <v-text-field placeholder="Email"
                  v-model="email"  
                  required outlined
                  dense
                  append-icon="mdi-email"
                />
              </div>
              <!-- END: Email --->
              <div class="text-center pt-1 mb-6 pb-1">
                <v-btn block color="primary" depressed @click="send()" large>
                  SEND
                </v-btn>
              </div>
              <div class="flex items-center justify-between pb-2">
                <span class="text-sm ml-2 hover:text-blue-500 cursor-pointer" @click="goToLogin()">Go to login</span>
                <span class="text-sm ml-2 hover:text-blue-500 cursor-pointer" @click="goToHome()">Go Back Home</span>
              </div>
            </form>
          </div>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
export default {
  data: () => ({
    email: null
  }),

  methods:{
    send(){

      this.$store.dispatch("resetPasswordEmail", { email: this.email })

      this.email = ""

    },

    goToLogin(){
      this.$router.push({ name: "Login" })
    },
    goToHome(){
      this.$router.push({ name: "Landing"})
    }
  }
};
</script>

<style scoped>
.emblem-style {
  border: 2px solid #efefef;
  box-shadow: 0 0 8px rgb(0 0 0 / 50%);
  padding: 5px;
  border-radius: 50%;
}
.loginBtn {
  margin-left: 50px;
}

#animated {
  background: url("../../assets/images/Kilimanjaro.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

</style>